<template>
  <v-dialog
    v-model="dialogEditarHistorico"
    @click:outside="$emit('update:dialogEditarHistorico', false)"
    @keydown.esc="$emit('update:dialogEditarHistorico', false)"
    width="500"
    scrollable
  >
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        Editar Tempo
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogEditarHistorico', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 py-8">
        <v-row>
          <!-- Data -->
          <v-col cols="12">
            <DataField label="Data" :data_sync.sync="data" obrigatorio />
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="hora_inicio"
              label="Hora Início"
              type="time"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="hora_fim"
              label="Hora Fim"
              type="time"
              dense
              hide-details
            ></v-text-field>
          </v-col>

          <!-- Nota -->
          <v-col cols="12">
            <v-textarea v-model="note" label="Nota" dense rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn outlined @click="$emit('update:dialogEditarHistorico', false)">
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :loading="loading" @click="editarTempo">
          Editar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { putAtividadeEditarTempo } from "@/api/gestor/atividades_clientes.js";
export default {
  name: "EditarHistoricoTempo",

  props: {
    dialogEditarHistorico: {
      type: Boolean,
    },
    tempo: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
      data: null,
      hora_inicio: "00:00",
      hora_fim: "00:00",
      note: "",
      jornada_trabalho: null,
    };
  },

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  methods: {
    editarTempo() {
      this.loading = true;

      const tempo = {
        id: this.tempo.id,
        data: this.data,
        hora_inicio: this.hora_inicio,
        hora_fim: this.hora_fim,
        note: this.note,
      };

      putAtividadeEditarTempo(tempo.id, tempo)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.result == "success") {
              this.$emit("update:dialogEditarHistorico", false);
              this.$emit("fetch-historico");
            }
            if (response.data.result == "failed") {
              this.$toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 406) {
            this.$toast.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {
    const { data, hora_inicio, hora_fim, note, jornada_trabalho } = this.tempo;

    this.jornada_trabalho = jornada_trabalho;
    this.data = data;
    this.note = note;
    this.hora_inicio = hora_inicio;
    this.hora_fim = hora_fim;
  },
};
</script>

<style></style>
